exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-charts-js": () => import("./../../../src/pages/charts.js" /* webpackChunkName: "component---src-pages-charts-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-templates-chart-template-js": () => import("./../../../src/templates/chart-template.js" /* webpackChunkName: "component---src-templates-chart-template-js" */),
  "component---src-templates-release-template-js": () => import("./../../../src/templates/release-template.js" /* webpackChunkName: "component---src-templates-release-template-js" */)
}

